var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: dialog }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    attrs: {
                      disabled: _vm.disabled,
                      "e2e-space-create-folder": "",
                    },
                  },
                  { ...dialog }
                ),
                [
                  _c("v-icon", { attrs: { left: "", color: "secondary" } }, [
                    _vm._v("create_new_folder"),
                  ]),
                  _vm._v(" Create "),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.newFolderDialog,
        callback: function ($$v) {
          _vm.newFolderDialog = $$v
        },
        expression: "newFolderDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "title lighten-2 secondary--text" },
              [
                _c("v-icon", { attrs: { color: "secondary", left: "" } }, [
                  _vm._v("create_new_folder"),
                ]),
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v("new folder"),
                ]),
              ],
              1
            ),
          ]),
          _c("v-divider", { staticClass: "mb-1" }),
          _c(
            "v-card-text",
            { staticClass: "mt-1" },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("Folder name"),
              ]),
              _c("v-text-field", {
                attrs: {
                  label: "Folder name here",
                  "e2e-space-folder-input": "",
                  autofocus: "",
                  outlined: "",
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.createFolder.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.folderName,
                  callback: function ($$v) {
                    _vm.folderName = $$v
                  },
                  expression: "folderName",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: {
                    color: "secondary",
                    text: "",
                    "e2e-space-folder-cancel": "",
                    disabled: _vm.creatingFolder,
                  },
                  on: {
                    click: function ($event) {
                      _vm.newFolderDialog = false
                    },
                  },
                },
                [_vm._v("cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !_vm.folderName.length,
                    loading: _vm.creatingFolder,
                    text: "",
                    color: "primary",
                    "e2e-space-folder-confirm": "",
                  },
                  on: { click: _vm.createFolder },
                },
                [_vm._v(" create folder ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }